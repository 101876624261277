var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.profileData).length)?_c('div',{attrs:{"id":"user-profile"}},[_c('b-alert',{attrs:{"variant":"danger","show":(_vm.member_type !== 2 && _vm.member_type !== 3 && !_vm.profileData.kin.length) || !_vm.profileData.payment_information.length}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Please complete your profile for review. "),_c('br'),_c('b',[_vm._v("Account will remain pending until profile is complete.")])])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"4"}},[(_vm.profileData.status === 'Approved')?_c('b-card',[_c('b-card-title',{attrs:{"title-tag":"h2"}},[_vm._v(" Membership Number ")]),_c('b-card-body',[_vm._v(" "+_vm._s(_vm.profileData.registration_number)+" ")])],1):_vm._e(),_c('b-card',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Change Profile Photo ")]),_c('b-avatar',{attrs:{"src":_vm.profileData.avatar,"size":"330px","rounded":""}}),_c('validation-observer',{ref:"updateAvatarForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateAvatar.apply(null, arguments)}}},[_c('div',[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Update Image","label-for":"change-avatar"}},[_c('b-form-file',{attrs:{"id":"avatar","state":errors.length > 0 ? false : null,"name":"avatar","accept":".jpg,.jpeg,.png"},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_avatar_too_big_error),expression:"show_avatar_too_big_error"}],staticClass:"text-danger"},[_vm._v(" File too large. Max size 5MB. ")])]}}],null,true)}),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Image" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)])]}}],null,false,3290601913)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"8"}},[_c('b-card',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Update Details ")]),_c('validation-observer',{ref:"updateDetailsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateDetails.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Membership Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.member_types,"size":"md"},model:{value:(_vm.member_type),callback:function ($$v) {_vm.member_type=$$v},expression:"member_type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-form-group',{attrs:{"label":_vm.member_type === 3 ? 'Guardian\'s Surname' : 'Surname',"label-for":"surname"}},[_c('validation-provider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","state":errors.length > 0 ? false : null,"name":"register-surname","placeholder":"Doe"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-form-group',{attrs:{"label":_vm.member_type === 3 ? 'Guardian\'s Other Names' : 'Other Names',"label-for":"other_names"}},[_c('validation-provider',{attrs:{"name":"Other Names","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_names","state":errors.length > 0 ? false : null,"name":"Other Names","placeholder":"John"},model:{value:(_vm.other_names),callback:function ($$v) {_vm.other_names=$$v},expression:"other_names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Business Name","label-for":"business-name"}},[_c('validation-provider',{attrs:{"name":"Business Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business-name","state":errors.length > 0 ? false : null,"name":"business-name","placeholder":"Enter Business Name"},model:{value:(_vm.business_name),callback:function ($$v) {_vm.business_name=$$v},expression:"business_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-form-group',{attrs:{"label":_vm.member_type === 3 ? 'Guardian\'s ID No or Passport No.' : 'ID Number or Passport Number',"label-for":"id_number"}},[_c('validation-provider',{attrs:{"name":"id_number","rules":"required","vid":"id_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_number","state":errors.length > 0 ? false : null,"name":"id-number","placeholder":"*********"},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=$$v},expression:"id_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Business Registration Number","label-for":"business_registration_number"}},[_c('validation-provider',{attrs:{"name":"Business Registration Number","rules":"required","vid":"business_registration_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_registration_number","state":errors.length > 0 ? false : null,"name":"business-registration-number","placeholder":"Enter Business Registration Number"},model:{value:(_vm.business_registration_number),callback:function ($$v) {_vm.business_registration_number=$$v},expression:"business_registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 1 || _vm.member_type === 5 || _vm.member_type === '')?_c('b-form-group',{attrs:{"label":"KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kra_pin","state":errors.length > 0 ? false : null,"name":"kra-pin","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Business's KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_kra_pin","state":errors.length > 0 ? false : null,"name":"KRA PIN","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type === 4)?_c('b-form-group',{attrs:{"label":"Guardian's KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"guardian_kra_pin","state":errors.length > 0 ? false : null,"name":"kra-pin","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),(_vm.member_type === 5)?_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(_vm.member_type === 5)?_c('b-form-group',{attrs:{"label":"Deceased Person's Name","label-for":"deceased_person_name"}},[_c('validation-provider',{attrs:{"name":"Deceased Person Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"deceased_person_name","state":errors.length > 0 ? false : null,"name":"Deceeased Person Name","placeholder":"John Doe"},model:{value:(_vm.deceased_person_name),callback:function ($$v) {_vm.deceased_person_name=$$v},expression:"deceased_person_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{staticClass:"mb-1",attrs:{"lg":"6","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"5","sm":"12"}},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" Gender: ")])]),_c('b-col',{attrs:{"lg":"7","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gender","rules":"required","vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.gender_options),function(gender){return _c('b-form-radio',{key:gender,staticClass:"mx-2",attrs:{"plain":"","value":gender},model:{value:(_vm.member_gender),callback:function ($$v) {_vm.member_gender=$$v},expression:"member_gender"}},[_vm._v(" "+_vm._s(gender)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.stage_name || (_vm.member_type === 1 || _vm.member_type === 4))?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Stage Name","label-for":"stage_name"}},[_c('validation-provider',{attrs:{"name":"Stage Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"stage_name","state":errors.length > 0 ? false : null,"name":"Stage Name"},model:{value:(_vm.stage_name),callback:function ($$v) {_vm.stage_name=$$v},expression:"stage_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 1 || _vm.member_type === 4 || _vm.member_type === 5 || _vm.member_type === '')?_c('b-form-group',{attrs:{"label":_vm.member_type === 4 ? 'Guardian\'s Email(Can\'t Edit)' : 'Email(Can\'t Edit)',"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"personal_email","readonly":"","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Business Email(Can't Edit)","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_email","readonly":"","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 1 || _vm.member_type === 4 || _vm.member_type === 5 || _vm.member_type === '')?_c('b-form-group',{attrs:{"label":_vm.member_type === 4 ? 'Guardian\'s Phone Number(Can\'t Edit)' : 'Phone Number(Can\'t Edit)',"label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"personal_phone_number","readonly":"","state":errors.length > 0 ? false : null,"name":"phone-number","placeholder":"07000000"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Business Phone Number(Can't Edit)","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","state":errors.length > 0 ? false : null,"name":"phone-number","placeholder":"07000000"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),(_vm.member_type === 4)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 4)?_c('b-form-group',{attrs:{"label":"Minor's Name","label-for":"child_name"}},[_c('validation-provider',{attrs:{"name":"Minor's Name","rules":"required","vid":"child_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_name"},model:{value:(_vm.child_name),callback:function ($$v) {_vm.child_name=$$v},expression:"child_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type === 4)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 4)?_c('b-form-group',{attrs:{"label":"Relationship","label-for":"child_relationship"}},[_c('validation-provider',{attrs:{"name":"Relationship","rules":"required","vid":"child_relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_relationship"},model:{value:(_vm.child_relationship),callback:function ($$v) {_vm.child_relationship=$$v},expression:"child_relationship"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type === 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type === 3)?_c('b-form-group',{attrs:{"label":"Age","label-for":"age"}},[_c('validation-provider',{attrs:{"name":"Age","rules":"required","vid":"age"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"id":"age","min":"1","max":"18"},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Details" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]}}],null,false,375856300)})],1),_c('b-card',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Update Password ")]),_c('validation-observer',{ref:"updatePasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter Old Password","label-for":"old-password"}},[_c('validation-provider',{attrs:{"name":"Old Password","rules":"required","vid":"old_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"old_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"Old Password","placeholder":"············"},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Enter New Password","label-for":"new-password"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"new_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"New Password","placeholder":"············"},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm New Password","label-for":"confirm-new-password"}},[_c('validation-provider',{attrs:{"name":"Password Confirmation","rules":"required","vid":"confirm_new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"Password Confirmation","placeholder":"············"},model:{value:(_vm.confirm_new_password),callback:function ($$v) {_vm.confirm_new_password=$$v},expression:"confirm_new_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Password" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]}}],null,false,1983603258)})],1)],1)],1),_c('b-row',[(_vm.member_type !== 2 && _vm.member_type !== 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-card',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Update Next Of Kin ")]),_c('validation-observer',{ref:"updateNextOfKinForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateNextOfKin.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Next Of Kin's Name","rules":"required","vid":"next_of_kin_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Names","label-for":"nex-of-kin-name"}},[_c('b-form-input',{attrs:{"id":"next_of_kin_name","state":errors.length > 0 ? false : null,"name":"Next Kin's Name"},model:{value:(_vm.next_of_kin_name),callback:function ($$v) {_vm.next_of_kin_name=$$v},expression:"next_of_kin_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"next-of-kin-phone-number"}},[_c('b-form-input',{attrs:{"id":"next_of_kin_phone_number","name":"Next Of Kin Phone Number"},model:{value:(_vm.next_of_kin_phone_number),callback:function ($$v) {_vm.next_of_kin_phone_number=$$v},expression:"next_of_kin_phone_number"}})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Next Of Kin Relationship","rules":"required","vid":"relationship"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Relationship","label-for":"next-of-kin-relationship"}},[_c('b-form-input',{attrs:{"id":"next_of_kin_relationship","state":errors.length > 0 ? false : null,"name":"Relationship"},model:{value:(_vm.next_of_kin_relationship),callback:function ($$v) {_vm.next_of_kin_relationship=$$v},expression:"next_of_kin_relationship"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.profileData.kin.length)?_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"identification document","label":"National ID, Passport or Birth Certificate"}},[_c('validation-provider',{attrs:{"name":"Next of Kin ID Document","vid":"id_document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"next-of-kin-identification-document","name":"next-of-kin-identification-document","accept":".pdf"},model:{value:(_vm.identification_document),callback:function ($$v) {_vm.identification_document=$$v},expression:"identification_document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Next Of Kin" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]}}],null,false,1378600054)})],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-card',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Update Payment Information ")]),_c('validation-observer',{ref:"updatePaymentInformationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updatePaymentInformation.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"bank_name","label":"Bank Name"}},[_c('validation-provider',{attrs:{"name":"Bank Name","vid":"bank_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-name","name":"bank-name","state":errors.length > 0 ? false:null},model:{value:(_vm.bank_name),callback:function ($$v) {_vm.bank_name=$$v},expression:"bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"bank_account_name","label":"Enter Bank Account Name"}},[_c('validation-provider',{attrs:{"name":"Bank Account Name","vid":"bank_account_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-account-name","name":"bank-account-name","state":errors.length > 0 ? false:null},model:{value:(_vm.bank_account_name),callback:function ($$v) {_vm.bank_account_name=$$v},expression:"bank_account_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"bank_account_number","label":"Enter Bank Account Number"}},[_c('validation-provider',{attrs:{"name":"Bank Account number","vid":"bank_account_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-account-number","name":"bank-account-number","state":errors.length > 0 ? false:null},model:{value:(_vm.bank_account_number),callback:function ($$v) {_vm.bank_account_number=$$v},expression:"bank_account_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"bank_branch","label":"Enter Bank Branch"}},[_c('validation-provider',{attrs:{"name":"Bank Branch","vid":"bank_branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bank-branch","name":"bank-branch","state":errors.length > 0 ? false:null},model:{value:(_vm.bank_branch),callback:function ($$v) {_vm.bank_branch=$$v},expression:"bank_branch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"mobile_money_phone_number","label":"Enter Mobile Money Registered Phone Number"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Phone Number","vid":"mobile_money_phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile-money-phone-number","name":"mobile-money-phone-number","state":errors.length > 0 ? false:null},model:{value:(_vm.mobile_money_phone_number),callback:function ($$v) {_vm.mobile_money_phone_number=$$v},expression:"mobile_money_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"mobile_money_provider","label":"Enter Mobile Money Service Provider"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Provider","vid":"mobile_money_provider"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile-money-provider","name":"mobile-money-provider","state":errors.length > 0 ? false:null},model:{value:(_vm.mobile_money_provider),callback:function ($$v) {_vm.mobile_money_provider=$$v},expression:"mobile_money_provider"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label-for":"mobile_money_registered_name","label":"Enter Mobile Money Registered Name"}},[_c('validation-provider',{attrs:{"name":"Mobile Money Registered Name","vid":"mobile_money_registered_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile-money-registered-name","name":"mobile-money-registered-name","state":errors.length > 0 ? false:null},model:{value:(_vm.mobile_money_registered_name),callback:function ($$v) {_vm.mobile_money_registered_name=$$v},expression:"mobile_money_registered_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Payment Information" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)]}}],null,false,3008607442)})],1)],1),(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-card',[(_vm.member_type === 2 || _vm.member_type === 3)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h5',[_vm._v("Director Details")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":_vm.addDirector}},[_vm._v(" Add Director ")])],1)],1),_c('validation-observer',{ref:"updatePartnersInformationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updatePartnersInformation.apply(null, arguments)}}},[_vm._l((_vm.partners_counter),function(counter){return _c('b-row',{key:counter},[_c('b-col',{attrs:{"cols":"6"}},[_c('h6',[_vm._v(_vm._s(counter)+_vm._s(_vm.nth(counter))+" Director")])]),_c('b-col',{attrs:{"cols":"6"}},[(_vm.partners_counter > 1)?_c('b-button',{staticClass:"float-right",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.removeDirector(counter)}}},[_vm._v("Delete "+_vm._s(counter)+_vm._s(_vm.nth(counter))+" Director")]):_vm._e()],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Surname","label-for":counter+'-first-name'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+' Director\'s Surname',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-first-name',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s Surname',"placeholder":"Enter Director First Name"},model:{value:(_vm.partners_first_name[counter]),callback:function ($$v) {_vm.$set(_vm.partners_first_name, counter, $$v)},expression:"partners_first_name[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Other Names","label-for":counter+'-last-name'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+' Director\'s Other Names',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-last-name',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s Other Names',"placeholder":"Enter Director Last Name"},model:{value:(_vm.partners_last_name[counter]),callback:function ($$v) {_vm.$set(_vm.partners_last_name, counter, $$v)},expression:"partners_last_name[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"ID Number","label-for":counter+'-id-number'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+' Director\'s ID Number',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-id-number',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s ID Number',"placeholder":"Enter Director ID Number"},model:{value:(_vm.partners_id_number[counter]),callback:function ($$v) {_vm.$set(_vm.partners_id_number, counter, $$v)},expression:"partners_id_number[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":counter+'-phone-number'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+' Director\'s Phone Number',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-phone-number',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s Phone Number',"placeholder":"Enter Director Phone Number"},model:{value:(_vm.partners_phone_number[counter]),callback:function ($$v) {_vm.$set(_vm.partners_phone_number, counter, $$v)},expression:"partners_phone_number[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":counter+'-email'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+' Director\'s Email',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-email',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s Email',"placeholder":"Enter Director Email"},model:{value:(_vm.partners_email[counter]),callback:function ($$v) {_vm.$set(_vm.partners_email, counter, $$v)},expression:"partners_email[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Shares Held","label-for":counter+'-shares'}},[_c('validation-provider',{attrs:{"name":counter+''+_vm.nth(counter)+'Director\'s Shares',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter+'-director-shares',"state":errors.length > 0 ? false : null,"name":counter+''+_vm.nth(counter)+' Director\'s shares',"placeholder":"Enter Director's Shares"},model:{value:(_vm.partners_shares[counter]),callback:function ($$v) {_vm.$set(_vm.partners_shares, counter, $$v)},expression:"partners_shares[counter]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Update Partners Information" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],2)]}}],null,false,1247962733)})],1):_vm._e()],1)],1):_vm._e()],1),(_vm.profileData.roles)?_c('b-card',[_c('b-card-body',[_c('span',{staticClass:"font-bold"},[_vm._v(" Member Role: ")]),_c('span',{staticClass:"m_title"},[_vm._v(" "+_vm._s(_vm.profileData.roles.replaceAll('"', ''))+" ")])])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }